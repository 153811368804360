@layer components {
  .player-wrapper {
    @apply w-full min-h-[var(--player-mobile-h)] md:min-h-[var(--player-h)] z-50 bottom-0 bg-brand-neutral-900 border-t border-brand-neutral-700
    md:grid md:grid-cols-3 max-md:flex max-md:flex-col-reverse gap-4 md:px-4 md:py-2;
  }

  .player-infos {
    @apply grow grid items-stretch overflow-hidden gap-3 grid-rows-1 max-md:ps-2 max-md:min-h-[calc(var(--player-mobile-h)-6px)];
  }

  .progressbar {
    @apply w-full md:mt-1;
  }

  .queuelist-button,
  .speed-button,
  .backforward-button,
  .sound-button,
  .queuelist-button {
    & .icon {
      @apply stroke-none fill-brand-neutral-400;
    }

    &:not([disabled]):hover .icon {
      @apply fill-white;
    }
  }

  .snooze-button {
    & .icon {
      @apply fill-none stroke-brand-neutral-400;
    }

    &:not([disabled]):hover .icon {
      @apply stroke-white;
    }
  }

  .next-button {
    & .icon {
      @apply fill-brand-neutral-400 stroke-brand-neutral-400;
    }

    &:not([disabled]):hover .icon {
      @apply fill-white stroke-white;
    }
  }

  .speed-button {
    & span {
      @apply text-brand-neutral-400;
    }

    &:not([disabled]):hover span {
      @apply text-white;
    }
  }

  .queuelist-button,
  .next-button,
  .snooze-button,
  .speed-button,
  .backforward-button,
  .sound-button {
    @apply aspect-square h-10;

    .icon,
    span {
      @apply transition-colors;
    }

    &[disabled] .icon,
    &[disabled] span {
      @apply cursor-not-allowed;
    }
  }

  .play-button {
    @apply flex justify-center items-center btn-primary aspect-square rounded-full h-10 transition-all;
  }

  .player-timeline-controls,
  .player-mobile-timeline-controls {
    @apply max-md:flex max-md:flex-col-reverse;
  }

  .player-timeline,
  .player-mobile-timeline {
    @apply flex items-start gap-4;

    .player-timeline-actions {
      @apply flex items-start -me-56 -mt-4;

      button {
        @apply h-10 w-8;
      }
    }
  }

  .player-mobile-timeline {
    @apply w-full;

    .player-timeline-actions {
      @apply hidden;
    }
  }

  .player-controls {
    @apply flex justify-center items-center space-x-4;
  }

  .player-mobile-controls {
    @apply flex justify-center min-h-[var(--player-mobile-h)] max-md:hidden;

    .queuelist-button,
    .next-button,
    .play-button,
    .sound-button {
      @apply hidden;
    }
  }

  input[type='range']::-moz-range-thumb {
    @apply opacity-0;
  }
  input[type='range']::-webkit-slider-thumb {
    @apply opacity-0;
  }

  .progress-bar {
    @apply rounded;

    &::-moz-range-track,
    &::-webkit-slider-runnable-track {
      @apply border-none rounded w-full bg-brand-neutral-600;
    }
  }

  .progress-bar-mobile {
    @apply appearance-none h-1.5 cursor-pointer absolute w-full bg-brand-neutral-600;

    &::-moz-range-track,
    &::-webkit-slider-runnable-track {
      @apply border-none rounded-none w-full bg-brand-neutral-600;
    }

    &[disabled] {
      @apply cursor-not-allowed bg-brand-neutral-600;

      &::-moz-range-track,
      &::-webkit-slider-runnable-track {
        @apply bg-brand-neutral-600;
      }
    }
  }

  .progress-bar-loaded {
    @apply bg-brand-neutral-300 h-1.5 absolute z-10 md:rounded-full cursor-pointer pointer-events-none;
  }

  .progress-bar-played {
    @apply bg-primary h-1.5 absolute z-20 md:rounded-l-full cursor-pointer pointer-events-none;
  }

  .progress-time {
    @apply flex;
  }

  .progress-time-mobile {
    @apply hidden;
  }

  div[aria-modal] {
    .progress-bar-mobile {
      @apply rounded;
    }

    .progress-bar-loaded {
      @apply rounded-full;
    }

    .progress-bar-played {
      @apply rounded-l-full;
    }

    .progress-time-mobile {
      @apply flex;
    }
  }

  .player-wrapper .icon,
  .bottom-sheet .icon {
    &.play,
    &.pause {
      @apply w-5 h-5 text-white;
    }
  }

  .bottom-sheet .player-mobile-wrapper {
    @apply max-w-[80%] mx-auto pb-8 flex flex-col items-center;

    .player-infos {
      @apply mt-5;
    }

    .progressbar {
      @apply mt-5;
    }

    .primary-actions {
      @apply h-full w-full flex justify-between items-center mt-4;
    }

    .secondary-actions {
      @apply flex mt-7 items-center space-x-5 bg-brand-neutral-700 rounded-full px-6 py-2;
    }
  }
}
