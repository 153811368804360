#layout {
  @apply flex w-full h-full relative flex-col;

  .content {
    @apply h-full w-full flex flex-col relative sm:pb-[var(--player-mobile-h)] md:pb-0 px-10 overflow-auto;

    & > div {
      @apply w-full flex flex-col pb-10 sm:pb-6;
    }
  }

  .full-screen-content {
    @apply h-full w-full flex flex-col relative pb-0 overflow-hidden;

    & > div {
      @apply min-h-[calc(100vh-var(--header-h))] md:min-h-[calc(100vh-var(--header-h)-var(--player-h))] w-full h-full flex flex-col;
    }
  }
}
