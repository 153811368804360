.header {
  @apply top-0 z-30 w-full h-14 flex justify-between sm:hidden md:visible;

  &.opaque {
    @apply bg-brand-neutral-950;
  }

  & > div {
    @apply relative;
  }
}

.full-screen-header {
  @apply top-0 z-30 w-full h-14 flex justify-between bg-none backdrop-blur-sm sm:hidden;

  &.opaque {
    @apply bg-brand-neutral-950;
  }

  & > div {
    @apply relative;
  }
}

