.navigation{
  @apply relative hidden md:flex flex-auto h-full min-w-[var(--navigation-w)] #{!important};

  > nav {
    @apply flex flex-col h-full w-full #{!important};

    > ul li button svg.settings {
      @apply w-6 h-6 stroke-none;
    }

    > .main-navigation {
      @apply grow;
    }

    > .logo {
      @apply px-1 #{!important};
    }
  }
}

.account-navigation {
  @apply fixed bottom-0 z-30 w-72 h-auto -translate-y-[calc(var(--player-mobile-h)+4em)] md:-translate-y-[calc(var(--player-h)+4em)];

  &:not(.open) {
    @apply hidden;
  }

  & > nav {
    @apply popover;
  }
}

.promoCodeElement {
  @apply flex flex-col gap-2 p-3 border rounded border-brand-neutral-700 font-normal text-[12px];

  .icon {
    @apply text-secondary text-2xl w-6;
  }

  & > a {
    @apply font-bold text-brand-neutral-50;

    &:hover {
      @apply bg-transparent;
    }
  }

  .promoCodeElementTitle {
    @apply font-bold text-brand-neutral-50;
  }

  .promoCodeElementDescription {
    @apply text-brand-neutral-300;
  }
}
