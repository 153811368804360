button,
p,
label,
h1,
h2,
h3,
h4,
h5 {
  @apply tracking-wide;
}

.title-h1 {
  @apply text-4xl font-bold;
}

.title-h2 {
  @apply text-2xl font-bold mb-5 mt-8;
}

.title-h3 {
  @apply text-xl font-semibold mb-5;
}

.title-h4 {
  @apply font-bold mb-5;
}

.title-card {
  @apply font-sans font-bold text-2xl tracking-wide mb-5 md:text-xl;
}
